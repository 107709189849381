import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    CartAddButton: () =>
      import('./js/frontend/CartAddButton').then((m) => m.CartAddButton),
    CartAddress: () =>
      import('./js/frontend/CartAddress').then((m) => m.CartAddress),
    CartDelivery: () =>
      import('./js/frontend/CartDelivery').then((m) => m.CartDelivery),
    CartDeliveryDate: () =>
      import('./js/frontend/CartDeliveryDate').then((m) => m.CartDeliveryDate),
    CartIcon: () => import('./js/frontend/CartIcon').then((m) => m.CartIcon),
    CartInfo: () => import('./js/frontend/CartInfo').then((m) => m.CartInfo),
    CartMessage: () =>
      import('./js/frontend/CartMessage').then((m) => m.CartMessage),
    CartPreview: () =>
      import('./js/frontend/CartPreview').then((m) => m.CartPreview),
    CartQty: () => import('./js/frontend/CartQty').then((m) => m.CartQty),
    CartScope: () => import('./js/frontend/CartScope').then((m) => m.CartScope),
    EnterKey: () => import('./js/frontend/EnterKey').then((m) => m.EnterKey),
  },
  [ModType.FRONTEND_EXEC]: () =>
    import('./js/frontend/HookForms').then((m) => m.HookForms()),
  [ModType.FRONTEND_CODE]: {
    postCartForm: () => import('./js/postFormData').then((m) => m.postFormData),
    addToCart: () => import('./js/frontend/addToCart').then((m) => m.addToCart),
  },
};
export default index;
