import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    SearchSolrFilter: () =>
      import('./js/frontend/SearchSolrFilter').then((m) => m.SearchSolrFilter),
    SearchSolrFilterApply: () =>
      import('./js/frontend/SearchSolrFilterApply').then(
        (m) => m.SearchSolrFilterApply,
      ),
    SearchSolrFilterFacet: () =>
      import('./js/frontend/SearchSolrFilterFacet').then(
        (m) => m.SearchSolrFilterFacet,
      ),
    SearchSolrFilterFacetMenu: () =>
      import('./js/frontend/SearchSolrFilterFacetMenu').then(
        (m) => m.SearchSolrFilterFacetMenu,
      ),
    SearchSolrFilterFacetSelect: () =>
      import('./js/frontend/SearchSolrFilterFacetSelect').then(
        (m) => m.SearchSolrFilterFacetSelect,
      ),
    SearchSolrFilterIntervalSelect: () =>
      import('./js/frontend/SearchSolrFilterIntervalSelect').then(
        (m) => m.SearchSolrFilterIntervalSelect,
      ),
    SearchSolrFilterSortSelect: () =>
      import('./js/frontend/SearchSolrFilterSortSelect').then(
        (m) => m.SearchSolrFilterSortSelect,
      ),
    SearchSolrFilterWord: () =>
      import('./js/frontend/SearchSolrFilterWord').then(
        (m) => m.SearchSolrFilterWord,
      ),
    SearchSolrSearch: () =>
      import('./js/frontend/SearchSolrSearch').then((m) => m.SearchSolrSearch),
    SolrSearchInput: () =>
      import('./js/frontend/SolrSearchInput').then((m) => m.SolrSearchInput),
  },
};
export default index;
