import {forPlugins, ModType} from '../_components/LazyLoading';

import {index as PI} from '../index-site';
import {PluginRunner} from '../jsmod/js/registerPlugin';
import htmx from 'htmx.org/dist/htmx.esm';
import {globalShortcutKeys} from '../_components/globalShortcutKeys';

const ME = window.ME || (window.ME = {PI});
ME.PI = PI;
if (!Object.hasOwn(ME, 'PL')) {
  ME.PL = [];
}

window.htmx = htmx;

forPlugins(window.ME.PI, window.ME.PL).all(ModType.FRONTEND_EXEC);

if (
  document.readyState === 'interactive' ||
  document.readyState === 'complete'
) {
  globalShortcutKeys();
  htmx.process(document.body);
  PluginRunner.getInstance().start(htmx);
} else {
  document.addEventListener('readystatechange', (e) => {
    const runner = PluginRunner.getInstance();
    if (
      !runner.started &&
      (document.readyState === 'interactive' ||
        document.readyState === 'complete')
    ) {
      htmx.process(document.body);
      runner.start(htmx);
    }
  });
}
