import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    Accordion: () => import('./js/frontend/Accordion').then((m) => m.Accordion),
    BackButton: () =>
      import('./js/frontend/BackButton').then((m) => m.BackButton),
    FAQList: () => import('./js/frontend/FAQList').then((m) => m.FAQList),
    FormSubmit: () =>
      import('./js/frontend/FormSubmit').then((m) => m.FormSubmit),
    Gallery: () => import('./js/frontend/Gallery').then((m) => m.Gallery),
    IframeHeight: () =>
      import('./js/frontend/IframeHeight').then((m) => m.IframeHeight),
    ImageSlide: () =>
      import('./js/frontend/ImageSlide').then((m) => m.ImageSlide),
    MasonryHelper: () =>
      import('./js/frontend/MasonryHelper').then((m) => m.MasonryHelper),
    Menu: () => import('./js/frontend/Menu').then((m) => m.Menu),
    MenuButton: () =>
      import('./js/frontend/MenuButton').then((m) => m.MenuButton),
    MenuResp: () => import('./js/frontend/MenuResp').then((m) => m.MenuResp),
    ParallaxScroll: () =>
      import('./js/frontend/ParallaxScroll').then((m) => m.ParallaxScroll),
    Script: () => import('./js/frontend/Script').then((m) => m.Script),
    SitePopup: () => import('./js/frontend/SitePopup').then((m) => m.SitePopup),
    Slider: () => import('./js/frontend/Slider').then((m) => m.Slider),
    StickyPanel: () =>
      import('./js/frontend/StickyPanel').then((m) => m.StickyPanel),
    Tab: () => import('./js/frontend/Tab').then((m) => m.Tab),
    Validation: () =>
      import('./js/frontend/validation').then((m) => m.Validation),
    VideoBg: () => import('./js/frontend/VideoBg').then((m) => m.VideoBg),
  },
};
export default index;
