import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_EXEC]: () =>
    import('./js/frontend/bldpopup').then((m) => new m.ModalLinks()),
  [ModType.FRONTEND_PLUGIN]: {
    ClosePopup: () =>
      import('./js/frontend/closepopup').then((m) => m.ClosePopup),
    Modal: () => import('./js/frontend/Modal').then((m) => m.Modal),
  },
};
export default index;
