import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    CodeSearch: () =>
      import('./js/frontend/CodeSearch').then((m) => m.CodeSearch),
    Finder: () => import('./js/frontend/Finder').then((m) => m.Finder),
  },
};
export default index;
