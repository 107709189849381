import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    VueOptionBlock: () =>
      import('./js/frontend/VueOptionBlock').then((m) => m.VueOptionBlock),
    OptionBlock: () =>
      import('./js/frontend/OptionBlock').then((m) => m.OptionBlock),
    OptionSelected: () =>
      import('./js/frontend/OptionSelected').then((m) => m.OptionSelected),
  },
};
export default index;
