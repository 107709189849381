import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    CheckDest: () => import('./js/frontend/CheckDest').then((m) => m.CheckDest),
    CheckDestTest: () =>
      import('./js/frontend/CheckDestTest').then((m) => m.CheckDestTest),
  },
};
export default index;
