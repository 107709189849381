import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    ProdFormGroupDate: () =>
      import('./js/frontend/ProdFormGroupDate').then(
        (m) => m.ProdFormGroupDate,
      ),
    ProdFormTicketDate: () =>
      import('./js/frontend/ProdFormTicketDate').then(
        (m) => m.ProdFormTicketDate,
      ),
    ProdFormTicketSession: () =>
      import('./js/frontend/ProdFormTicketSession').then(
        (m) => m.ProdFormTicketSession,
      ),
    TicketChangeDate: () =>
      import('./js/frontend/TicketChangeDate').then((m) => m.TicketChangeDate),
  },
};
export default index;
