import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    CloseOutApp: () =>
      import('./js/frontend/CloseOutApp').then((m) => m.CloseOutApp),
    WinchCalculatorApp: () =>
      import('./js/frontend/WinchCalculatorApp').then(
        (m) => m.WinchCalculatorApp,
      ),
    TerminalWinchCalculatorApp: () =>
      import('./js/frontend/TerminalWinchCalculatorApp').then(
        (m) => m.TerminalWinchCalculatorApp,
      ),
    MooringLineRecordApp: () =>
      import('./js/frontend/MooringLineRecordApp').then(
        (m) => m.MooringLineRecordApp,
      ),
    MerGasApp: () => import('./js/frontend/MerGasApp').then((m) => m.MerGasApp),
    MerMaintainApp: () =>
      import('./js/frontend/MerMaintainApp').then((m) => m.MerMaintainApp),
    ReportStats: () =>
      import('./js/frontend/ReportStatsApp').then((m) => m.ReportStatsApp),
    MerGasGraph: () =>
      import('./js/frontend/MerGasGraph').then((m) => m.MerGasGraph),
  },
};
export default index;
