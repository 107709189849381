import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    ListResult: () =>
      import('./js/frontend/ListResult').then((m) => m.ListResult),
    MapResult: () => import('./js/frontend/MapResult').then((m) => m.MapResult),
    Search: () => import('./js/frontend/Search').then((m) => m.Search),
  },
};
export default index;
