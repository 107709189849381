export function globalShortcutKeys() {
  window.addEventListener('keyup', (e) => {
    if (e.altKey && e.shiftKey) {
      if (e.code === 'Digit1') {
        window.open('/', '_blank');
        return false;
      } else if (e.code === 'Digit2') {
        window.open('/admin/', '_blank');
        return false;
      } else if (e.code === 'Digit9') {
        window.open('/~graphql/browse', '_blank');
        return false;
      }
    }
  });
}
