import $ from 'jquery';
import {ModType} from '../../_components/LazyLoading';
import {RefreshOptions} from '../../checkout2/js/postFormData';

export interface IMEPluginElement extends HTMLElement {
  MEPlugin?: object;
}

export interface IPluginType {
  frontend: (el: HTMLElement, opt: any) => object;
  cleanup?: (instance: object) => void;
  updated?: (
    instance: object,
    oldEL: HTMLElement,
    newEl: HTMLElement,
    options: RefreshOptions,
  ) => void;
}

const DEBUG = (...v: any) => {}; // console.log;

export class PluginRunner {
  public static getInstance(): PluginRunner {
    if (!JSMod.PlugRun) {
      JSMod.PlugRun = new PluginRunner();
    }
    return JSMod.PlugRun;
  }
  public started = false;

  public registered: Array<{
    plugin: string;
    module: string;
    constructor: IPluginType;
  }> = [];

  public queueList: Array<{
    el: IMEPluginElement;
    plugin: string;
    module: string;
    opt: any;
  }> = [];

  public async getPlugin(plugin: string, module: string): Promise<IPluginType> {
    const load =
      window.ME.PI?.[plugin.toLowerCase()]?.[ModType.FRONTEND_PLUGIN]?.[module];
    if (!load) {
      console.error(`Unable to find plugin (${plugin}.${module}`);
      return Promise.reject(`Unable to find plugin (${plugin}.${module})`);
    }
    return load();
  }

  public initPlugin(
    el: IMEPluginElement,
    plugin: string,
    module: string,
    opt: any,
  ): void {
    this.getPlugin(plugin, module).then((mod: IPluginType): void => {
      if (!el.MEPlugin) {
        try {
          el.MEPlugin = mod.frontend(el, opt);
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.error('Error initialising block', el, plugin, module);
          // tslint:disable-next-line:no-console
          console.trace(e);
        }
      }
    });
  }

  public deInitElement(el: IMEPluginElement): void {
    if (el.MEPlugin) {
      const [plugin, module] = el.getAttribute('data-mod').split('.', 2);
      this.getPlugin(plugin, module).then((plug) => {
        if (plug.cleanup) {
          plug.cleanup(el.MEPlugin);
        }
      });
    }
  }

  public start(htmx?): void {
    this.started = true;
    if (window['__INIT_VUE__'] && Array.isArray(window['__INIT_VUE__'])) {
      const init = window['__INIT_VUE__'];
      import('./vueRuntime').then((m) => {
        for (const v of init) m.startVue(v);
      });
    }
    window['__INIT_VUE__'] = {
      push: (v) =>
        import('./vueRuntime').then((m) => {
          m.startVue(v);
        }),
    };

    this.initBlocks(document.getElementsByClassName('me-block'));
    window.setTimeout(() => {
      this.shouldBeLoaded();
    }, 5000);
    if (htmx)
      htmx.onLoad((e) => {
        this.initBlocks(e.querySelectorAll('.me-Block'));
      });
  }

  public reinit(): void {
    this.initBlocks(document.getElementsByClassName('me-block'));
  }

  public replaceEl(el: IMEPluginElement, newEl: IMEPluginElement): void {
    const children = el.getElementsByClassName('me-block');
    for (let i = 0; i < children.length; i++) {
      this.deInitElement(children.item(i) as IMEPluginElement);
    }
    this.deInitElement(el);
    el.parentNode.replaceChild(newEl, el);
    const newChildren = newEl.getElementsByClassName('me-block');
    this.evalScripts(newEl);
    if (newEl.classList.contains('me-block')) {
      this.initElement(newEl);
    }
    for (let i = 0; i < newChildren.length; i++) {
      this.initElement(newChildren.item(i) as IMEPluginElement);
    }
  }

  public updateDom(body: HTMLElement, options: RefreshOptions = {}): void {
    const blockList = body.getElementsByClassName('me-block');
    for (let i = 0; i < blockList.length; i++) {
      const newEl = blockList.item(i) as IMEPluginElement;
      if (newEl.id && newEl.hasAttribute('data-mod')) {
        const oldEl = document.getElementById(newEl.id) as IMEPluginElement;
        if (oldEl && oldEl.MEPlugin) {
          const [plugin, module] = newEl.getAttribute('data-mod').split('.', 2);
          this.getPlugin(plugin, module).then((handler) => {
            if (handler.updated) {
              DEBUG('Update Block', oldEl, newEl);
              handler.updated(oldEl.MEPlugin, oldEl, newEl, options);
            }
          });
        }
      }
    }
  }

  public afterDomUpdate(e: Element): void {
    this.initBlocks(e.getElementsByClassName('me-block'));
  }

  public initBlocks(blockList: HTMLCollectionOf<Element>): void {
    for (let i = 0; i < blockList.length; i++) {
      const el = blockList.item(i) as IMEPluginElement;
      this.initElement(el);
    }
  }

  public shouldStopInit(el: IMEPluginElement): boolean {
    let stopInit = false;
    const $el = $(el);
    if (!el.hasAttribute('data-parent')) {
      const types = ['Accordion', 'Tab'];
      for (let i = 0; i < types.length; i++) {
        const $parent = $el.parent().closest(`.me-${types[i]}`);
        // console.log($parent.length, types[i], !$el.parents('details').attr('open'))
        if (
          $parent.length !== 0 &&
          !(
            types[i] === 'Tab' &&
            $el.parent().closest('.show-tab-content').length
          ) &&
          types[i] === 'Accordion' &&
          !$el.parents('details').attr('open')
        ) {
          stopInit = true;
          // console.log(stopInit);
          $el.attr('data-parent', $parent.attr('id'));
          break;
        }
      }
    }
    return stopInit;
  }

  public initElement(el: IMEPluginElement): void {
    const shouldStopInit = this.shouldStopInit(el);
    if (!shouldStopInit) {
      if (el.hasAttribute('data-mod') && !el.MEPlugin) {
        const [plugin, mod] = el.getAttribute('data-mod').split('.', 2);
        let opt = {};
        if (el.hasAttribute('data-opt')) {
          const optsAttr = el.getAttribute('data-opt');
          if (optsAttr.length > 0) {
            try {
              opt = JSON.parse(optsAttr);
            } catch (e) {
              console.error('Failed to parse data-opt for element', e, el);
            }
          }
        }
        this.initPlugin(el, plugin, mod, opt);
      }
    }
  }

  public shouldBeLoaded(): void {
    if (this.queueList.length > 0) {
      console.error('Frontend Libs Requested, but not handled', this.queueList);
    }
  }

  private evalScripts(newEl: IMEPluginElement): void {
    const scriptTags = newEl.getElementsByTagName('script');
    for (let i = 0; i < scriptTags.length; i++) {
      const oldTag = scriptTags.item(i) as HTMLScriptElement;
      const newScriptEl = document.createElement('script');

      Array.from(oldTag.attributes).forEach((attr) => {
        newScriptEl.setAttribute(attr.name, attr.value);
      });

      const scriptText = document.createTextNode(oldTag.innerHTML);
      newScriptEl.appendChild(scriptText);

      oldTag.parentNode.replaceChild(newScriptEl, oldTag);
    }
  }
}

const ME = window.ME || (window.ME = {});
const PLUGINS = ME.Plugins || (ME.Plugins = {});
const JSMod = ME.JSMod || (ME.JSMod = {});
const runner = PluginRunner.getInstance();
