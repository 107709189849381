import {IDynamicRegistry, IDynamicSiteItem} from './_components/LazyLoading';

import {index as admin14} from './admin14/entry-site';
import {index as artprofile} from './artprofile/entry-site';
import {index as buildaudio} from './buildaudio/entry-site';
import {index as buildform} from './buildform/entry-site';
import {index as buildpopup} from './buildpopup/entry-site';
import {index as buildprodgroup} from './buildprodgroup/entry-site';
import {index as buildwork} from './buildwork/entry-site';
import {index as course} from './course/entry-site';
import {index as custtjc} from './custtjc/entry-site';
import {index as checkout2} from './checkout2/entry-site';
import {index as custprintco} from './custprintco/entry-site';
import {index as echart} from './echart/entry-site';
import {index as event} from './event/entry-site';
import {index as fileattach} from './fileattach/entry-site';
import {index as form2} from './form2/entry-site';
import {index as googleanalytics} from './googleanalytics/entry-site';
import {index as googlemaps3} from './googlemaps3/entry-site';
import {index as googlerecaptcha} from './googlerecaptcha/entry-site';
import {index as inspect} from './inspect/entry-site';
import {index as instafeedjs} from './instafeedjs/entry-site';
import {index as locator} from './locator/entry-site';
import {index as mebuild2} from './mebuild2/entry-site';
import {index as media} from './media/entry-site';
import {index as paybase} from './paybase/entry-site';
import {index as payeway} from './payeway/entry-site';
import {index as payment} from './payment/entry-site';
import {index as paystripe} from './paystripe/entry-site';
import {index as prodauto} from './prodauto/entry-site';
import {index as prodflex} from './prodflex/entry-site';
import {index as prodoptions} from './prodoptions/entry-site';
import {index as prodorder} from './prodorder/entry-site';
import {index as prodsize} from './prodsize/entry-site';
import {index as searchsolr} from './searchsolr/entry-site';
import {index as shipcalc} from './shipcalc/entry-site';
import {index as ticket} from './ticket/entry-site';
import {index as trix} from './trix/entry-site';
import {index as frescojs} from '../lib/frescojs/entry-site';

export const index: IDynamicRegistry<IDynamicSiteItem> = {
  admin14,
  artprofile,
  buildaudio,
  buildform,
  buildpopup,
  buildprodgroup,
  buildwork,
  checkout2,
  course,
  custtjc,
  custprintco,
  event,
  echart,
  fileattach,
  form2,
  frescojs,
  googleanalytics,
  googlemaps3,
  googlerecaptcha,
  inspect,
  instafeedjs,
  locator,
  mebuild2,
  media,
  paybase,
  payeway,
  payment,
  paystripe,
  prodauto,
  prodflex,
  prodoptions,
  prodorder,
  prodsize,
  searchsolr,
  shipcalc,
  ticket,
  trix,
};
