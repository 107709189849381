import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    CardPay: () => import('./js/frontend/CardPay').then((m) => m.CardPay),
    SaveCard: () => import('./js/frontend/SaveCard').then((m) => m.SaveCard),
    SavedCard: () => import('./js/frontend/SavedCard').then((m) => m.SavedCard),
  },
};
export default index;
