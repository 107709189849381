import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    FlexChooser: () =>
      import('./js/Frontend/FlexChooser').then((m) => m.FlexChooser),
    FlexSelected: () =>
      import('./js/Frontend/FlexSelected').then((m) => m.FlexSelected),
  },
};
export default index;
