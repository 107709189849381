import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    Checkbox: () => import('./js/frontend/Checkbox').then((m) => m.Checkbox),
    DatePicker: () =>
      import('./js/frontend/DatePicker').then((m) => m.DatePicker),
    DropDown: () => import('./js/frontend/DropDown').then((m) => m.DropDown),
    FormSelectDoc: () =>
      import('./js/frontend/FormSelectDoc').then((m) => m.FormSelectDoc),
    FormDepends: () =>
      import('./js/frontend/FormDepends').then((m) => m.FormDepends),
    FormButtonValidate: () =>
      import('./js/frontend/FormButtonValidate').then(
        (m) => m.FormButtonValidate,
      ),
    FormSubmit: () =>
      import('./js/frontend/FormSubmit').then((m) => m.FormSubmit),
    RadioButton: () =>
      import('./js/frontend/RadioButton').then((m) => m.RadioButton),
    TextWithFormat: () =>
      import('./js/frontend/TextWithFormat').then((m) => m.TextWithFormat),
    TimePicker: () =>
      import('./js/frontend/TimePicker').then((m) => m.TimePicker),
    ValidateTextInput: () =>
      import('./js/frontend/ValidateTextInput').then(
        (m) => m.ValidateTextInput,
      ),
    WordBlank: () => import('./js/frontend/WordBlank').then((m) => m.WordBlank),
  },
};
export default index;
