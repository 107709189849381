// ModdEngine to export global Plugin list

import type {Component, Ref} from 'vue';
import type ImportType from '../importdata/js/ImportType';
import type {IPluginType} from '../jsmod/js/registerPlugin';
import type Block from '../mebuild2/js/Block';
import type {IFolderTypeFieldInfo} from '../admin14/js/getFolderInfo';
import type {IConfirmTabOptions} from '../admin14/js/Dialogs';
import type {IDocEditHistory} from '../admin14/js/EditHistory/DocEditHistory';
import type {IRuleType} from '../prodsimple/vue/Rules';
import {ImmixData} from '../admin14/js/MDocRepo/MDocTypes';

// window.ME.PL = [
//   'form2',
//   'promo',
//   'prodsimple',
//   'checkout2',
//   'mebuild2',
//   'buildpage',
// ];
export const enum ModType {
  ADMIN_COMP = 'vc',
  ADMIN_EXEC = 'ae',
  ADMIN_PLUG = 'ap',
  DOC_TOOL = 'dt',
  BUILD_BLOCKS = 'bb',
  FRONTEND_EXEC = 'fe',
  FRONTEND_PLUGIN = 'fp',
  FRONTEND_CODE = 'fc',
  IMPORT_TYPE = 'im',
  PROMO_ACTION = 'pa',
  PROMO_COND = 'pc',
  PROD_RULES = 'pr',
  LIST_OPTION = 'lp',
}
// const Registry: IDynamicRegistry = {
//   form2: {
//     [ModType.FRONTEND_PLUGIN]: {
//       Checkbox: () =>
//         import('../plugin/moddeng/form2/js/frontend/Checkbox').then(
//           a => a.default,
//         ),
//       DatePicker: () =>
//         import('../plugin/moddeng/form2/js/frontend/DatePicker').then(
//           a => a.default,
//         ),
//     },
//     [ModType.ADMIN_PLUG]: {
//       CKEditorLight: () =>
//         import('../plugin/moddeng/form2/js/admin/CKEditorLight'),
//     },
//     [ModType.BUILD_BLOCKS]: () =>
//       Promise.all([
//         import('../plugin/moddeng/form2/js/admin/FormCountry'),
//         import('../plugin/moddeng/form2/js/admin/FormDropDown'),
//         import('../plugin/moddeng/form2/js/admin/FormEmail'),
//       ]).then(([FormCountry, FormDropDown, FormEmail]) => ({
//         FormCountry: FormCountry.default,
//         FormDropDown: FormDropDown.default,
//         FormEmail: FormEmail.default,
//       })),
//   },
//   grouppromo: {
//     [ModType.PROMO_ACTION]: () =>
//       Promise.all([
//         import('../plugin/moddeng/grouppromo/js/ActionVisitGroup'),
//       ]).then(([ActionVisitGroup]) => ({
//         ActionVisitGroup: ActionVisitGroup.default,
//       })),
//   },
//   hacsuimport: {
//     [ModType.IMPORT_TYPE]: () => @deprecated - use entry-import.ts
//       Promise.all([
//         import('../plugin/moddeng/hacsuimport/js/import/import'),
//       ]).then(([HACSUUser]) => ({HACSUUser: HACSUUser.default})),
//   },
// };

export interface IDynamicComponent {
  comp: Component<any, any, any, any>;
  label: string;
}

export interface IDynamicRegistry<T> {
  [pluginName: string]: T;
}

export const enum CompType {
  ADMIN_DASHBOARD = 'ad',
  ADMIN_LIST = 'al',
  ADMIN_TOOL = 'at',
  ACTION_ACTION = 'aa',
  ACTION_COMMAND = 'acd',
  ACTION_COND = 'ac',
  ACTION_METHOD = 'am',
  EDIT_TAB = 'et',
  EDIT_FIELD = 'ef',
  EDIT_LIST_FIELD = 'el',
  PROMO_ACTION = 'pa',
  PROMO_COND = 'pc',
  LIST_EXPORT = 'le',
  LIST_IMPORT = 'li',
  LIST_TOOL = 'lt',
  SETTINGS_ITEM = 'si',
}

export const enum AdminToolType {
  EXPORT = 'e',
  IMPORT = 'i',
  TOOL = 't',
}

export interface ILazyVueCompBase {
  type: CompType;
  comp: () => Promise<Component>;
}

export interface IAdminList extends ILazyVueCompBase {
  type: CompType.ADMIN_LIST;
  icon: 'mdi-view-sequential' | 'mdi-view-module' | 'mdi-calendar-month';
  immix: string[];
  label: string;
  code: string;
}
export interface IAdminDashboard extends ILazyVueCompBase {
  type: CompType.ADMIN_DASHBOARD;
  icon: string;
  immix: string[];
  label: string;
}
export interface IAdminListTool extends ILazyVueCompBase {
  type: CompType.LIST_EXPORT | CompType.LIST_IMPORT | CompType.LIST_TOOL;
  icon: string;
  immix: string[];
  label: string;
}

export interface IPromoComp extends ILazyVueCompBase {
  code: string;
  label: string;
  type: CompType.PROMO_ACTION | CompType.PROMO_COND;
}

export interface IActionComp extends ILazyVueCompBase {
  code: string;
  label: string;
  type:
    | CompType.ACTION_ACTION
    | CompType.ACTION_COMMAND
    | CompType.ACTION_COND
    | CompType.ACTION_METHOD;
}
export interface IAdminTool extends ILazyVueCompBase {
  icon: string;
  immix: string[];
  label: string;
  type: CompType.ADMIN_TOOL;
  sub: AdminToolType;
  props?: Record<string, any>;
}

export interface IEditTab extends ILazyVueCompBase {
  icon: string;
  immix: string[];
  label: string;
  type: CompType.EDIT_TAB;
}

export interface ISettingsItem extends ILazyVueCompBase {
  type: CompType.SETTINGS_ITEM;
  icon: string;
  perm: number;
  label: string;
}

export interface IEditField extends ILazyVueCompBase {
  code: string;
  type: CompType.EDIT_FIELD | CompType.EDIT_LIST_FIELD;
}

type ILazyVueComp =
  | IAdminList
  | IAdminDashboard
  | IAdminListTool
  | IPromoComp
  | IActionComp
  | IAdminTool
  | IEditTab
  | IEditField
  | ISettingsItem;

export const enum ListOptionType {
  SORT = 's',
  FILTER = 'f',
}

export interface IListOptionSort {
  type: ListOptionType.SORT;
  immix: string;
  label: string;
  field: string;
  default?: 1 | -1;
}

export interface IListOptionFilter {
  type: ListOptionType.FILTER;
  immix: string;
  label: string;
  filter: string;
  icon: string;
  default?: true;
}

export type IListOption = IListOptionSort | IListOptionFilter;

export const enum ToolStatus {
  /** Tool is invalid for the doc that is open */
  Invalid,
  /** Tool is hidden for the current doc */
  Hidden,
  /** Tool is only in overflow menu */
  Overflow,
  /** Tool should be on toolbar, will go into overflow menu */
  Toolbar,
  /** Tool is a method of saving changes (Go Live, Save Draft, etc) */
  Save,
}

export interface IEditDocActionArgs {
  doc: ImmixData;
  dh: IDocEditHistory;
  data: Ref<{[key: string]: any}> | null;
  close: () => void;
  preSave?: () => boolean;
  fields: Readonly<IFolderTypeFieldInfo[]>;
  confirm: (o: IConfirmTabOptions) => Promise<boolean>;
}

export interface IEditDocTool {
  label: string;
  action: (arg: IEditDocActionArgs) => Promise<any>;
  icon: string;
  show: (doc: ImmixData) => ToolStatus;
  /**
   * Shortcut Key (Alt + <key>)
   */
  key?: string;
}

export interface IDynamicAdminItem {
  [ModType.ADMIN_PLUG]?: {
    [plugin: string]: () => Promise<(p?: any, ...params: any) => void>;
  };
  [ModType.ADMIN_EXEC]?: () => Promise<any>;
  [ModType.BUILD_BLOCKS]?: () => Promise<{
    [blockType: string]: typeof Block<any>;
  }>;
  [ModType.ADMIN_COMP]?: ILazyVueComp[];
  [ModType.IMPORT_TYPE]?: () => Promise<{
    [importName: string]: typeof ImportType;
  }>;
  [ModType.PROMO_ACTION]?: () => Promise<{
    [actionType: string]: Component<any, any, any, any>;
  }>;
  [ModType.PROMO_COND]?: () => Promise<{
    [condType: string]: Component<any, any, any, any>;
  }>;
  [ModType.LIST_OPTION]?: IListOption[];
  [ModType.DOC_TOOL]?: IEditDocTool[];
  [ModType.PROD_RULES]?: () => Promise<IRuleType<any>[]>;
}

export interface IDynamicSiteItem {
  [ModType.FRONTEND_PLUGIN]?: {[plugin: string]: () => Promise<IPluginType>};
  [ModType.FRONTEND_EXEC]?: () => Promise<any>;
  [ModType.FRONTEND_CODE]?: {[id: string]: () => Promise<Function>};
}

export function forPlugins<R>(
  registry: IDynamicRegistry<R>,
  pluginList: string[],
) {
  const livePlugins = pluginList
    .map((plug) => registry[plug])
    .filter((i) => !!i);
  function all<T>(
    type:
      | ModType.BUILD_BLOCKS
      | ModType.IMPORT_TYPE
      | ModType.PROMO_ACTION
      | ModType.ADMIN_EXEC
      | ModType.FRONTEND_EXEC,
  ): Promise<{[name: string]: T}> {
    const prList = [];
    for (const plugItem of livePlugins) {
      if (plugItem.hasOwnProperty(type)) {
        prList.push(plugItem[type]());
      }
    }
    return Promise.all(prList).then((loaded) =>
      // tslint:disable-next-line:prefer-object-spread
      loaded.reduce((t, i) => Object.assign(t, i), {}),
    );
  }
  function list<T>(type: ModType.PROD_RULES): Promise<T[]> {
    const prList = livePlugins
      .filter((pi) => pi.hasOwnProperty(type))
      .map((pi) => pi[type]());
    return Promise.all(prList).then((loaded) => [].concat(...loaded));
  }
  function comps(type: CompType): ILazyVueComp[] {
    return livePlugins
      .filter((pi) => pi.hasOwnProperty(ModType.ADMIN_COMP))
      .reduce((t, i) => {
        t.push(...i[ModType.ADMIN_COMP].filter((c) => c.type === type));
        return t;
      }, []);
  }
  function one<T>(type, plug, name): Promise<T> {
    return registry[type][plug][name]();
  }
  return {all, list, one, comps};
}

interface CurrentPlugs {
  listOptions(type: ListOptionType.FILTER): IListOptionFilter[];
  listOptions(type: ListOptionType.SORT): IListOptionSort[];
  listComps(type: CompType.EDIT_TAB): IEditTab[];

  listComps(type: CompType.ADMIN_TOOL): IAdminTool[];
  listComps(type: CompType.EDIT_FIELD | CompType.EDIT_LIST_FIELD): IEditField[];
  listComps(
    type:
      | CompType.ACTION_ACTION
      | CompType.ACTION_COMMAND
      | CompType.ACTION_COND
      | CompType.ACTION_METHOD,
  ): IActionComp[];
  listComps(
    type: CompType.LIST_EXPORT | CompType.LIST_IMPORT | CompType.LIST_TOOL,
  ): IAdminListTool[];
  listComps(type: CompType.ADMIN_LIST): IAdminList[];
  listComps(type: CompType.ADMIN_DASHBOARD): IAdminDashboard[];
  listComps(type: CompType.PROMO_ACTION | CompType.PROMO_COND): IPromoComp[];
  listTools(): IEditDocTool[];
}
interface FlatenablePlugins {
  [ModType.LIST_OPTION]: IListOption[];
  [ModType.ADMIN_COMP]: ILazyVueComp[];
  [ModType.DOC_TOOL]: IEditDocTool[];
}
function baseFlatPlugList(): FlatenablePlugins {
  return {
    [ModType.LIST_OPTION]: [],
    [ModType.ADMIN_COMP]: [],
    [ModType.DOC_TOOL]: [],
  };
}

let mCurrentPlugs: CurrentPlugs | null = null;
export function mCurrentPlugins(): CurrentPlugs {
  if (mCurrentPlugs !== null) {
    return mCurrentPlugs;
  }
  const allPlugins = window.ME.PL.map((plug) => window.ME.PI[plug]).filter(
    (i) => !!i,
  );
  const flatPlugins: FlatenablePlugins = allPlugins.reduce((t, i) => {
    for (const k of [ModType.LIST_OPTION, ModType.ADMIN_COMP, ModType.DOC_TOOL])
      if (i[k]) t[k] = t[k].concat(i[k]);
    return t;
  }, baseFlatPlugList());
  const listOptions = (type: ListOptionType) =>
    flatPlugins[ModType.LIST_OPTION].filter((i) => i.type === type) as any;
  const listComps = (type: CompType) =>
    flatPlugins[ModType.ADMIN_COMP].filter((i) => i.type === type) as any;
  const listTools = () => flatPlugins[ModType.DOC_TOOL];
  return (mCurrentPlugs = {listOptions, listComps, listTools});
}
