import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    SpeakText: () => import('./js/frontend/SpeakText').then((m) => m.SpeakText),
    AudioPlayer: () =>
      import('./js/frontend/AudioPlayer').then((m) => m.AudioPlayer),
  },
  [ModType.FRONTEND_CODE]: {
    GLightbox: () =>
      import('./js/frontend/GLightbox').then((m) => m.openLightbox),
  },
};
export default index;
