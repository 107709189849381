import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    FormFileUpload: () =>
      import('./js/frontend/FormFileUpload').then((m) => m.FormFileUpload),
    ProdFormFileArea: () =>
      import('./js/frontend/ProdFormFileArea').then((m) => m.ProdFormFileArea),
  },
};
export default index;
