import {IDynamicSiteItem, ModType} from '../../moddeng/_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_EXEC]: () => {
    if (document.getElementsByClassName('fresco').length > 0)
      return import('./js/frontend/fresco').then((m) => m.fresco());
    return Promise.resolve({});
  },
};
export default index;
