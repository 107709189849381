import {IDynamicSiteItem, ModType} from '../_components/LazyLoading';

export const index: IDynamicSiteItem = {
  [ModType.FRONTEND_PLUGIN]: {
    GoogleRecaptchaRuntime: () =>
      import('./js/frontend/GoogleRecaptchaRuntime').then(
        (m) => m.GoogleRecaptchaRuntime,
      ),
  },
};
export default index;
